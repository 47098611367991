import { ArrowLineRight } from "@phosphor-icons/react";
import ChatRoomMessageItem from "./MessageContainer";
import { Box, Tooltip } from "@mui/material";
import TextareaAutosize from "react-textarea-autosize";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

export default function ChatRoom({ show_chat, setShowChat, students, id_candidato, send_message,get_messages_from_student }) {
  
  const user = useSelector(store => store.AppReducer.user);
  const [message, setMessage] = useState('');
  const [student, setStudent] = useState({});

  const [messages, setMessages] = useState([]);

  
  const textRef = useRef();
  const messages_view = useRef();

  useEffect(() => {
    console.log('student update')
    setMessages(get_messages_from_student(id_candidato).filter((item) => item.warning != true))
    messages_view.current.scrollTop = messages_view.current.scrollHeight;
  }, [students]);

  useEffect(()=>{
    messages_view.current.scrollTop = messages_view.current.scrollHeight;
  },[show_chat])

  useEffect(()=>{
    setStudent(students.filter((item)=>item.id==id_candidato)[0])
  },[id_candidato])
  return (
    <div
      className="Transition-1"
      style={{
        height: "100%",
        width: "30rem",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color0)",
        borderRadius: "1rem 0 0 1rem",
        padding: "1rem",

        position: "absolute",
        top: "0",
        right: show_chat ? "0" : "-100vw",
      }}
    >
      {/* header */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1.4rem",
            fontWeight: "bold",
          }}
        >
          Chat de {student?.name}
        </span>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              Esconder chat
            </span>
          }
          placement="bottom"
          arrow
          onClick={() => setShowChat(false)}
        >
          <ArrowLineRight size={24} />
        </Tooltip>
      </div>

      {/* chat container */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: "100%",
          overflowY: "auto",
        }}
        ref={messages_view}
      >
        {messages?.map((message, index) => (
          <ChatRoomMessageItem
            key={index}
            {...{
              ...message,
              isMine: message.sender.id == user.id
            }}
          />
        ))}
      </div>

      {/* input */}
      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "0.5rem",
            padding: "0.8rem",
            borderRadius: "1rem",

            backgroundColor: "var(--background-color2)",
            height: "max-content",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(var(--accent-color1), 0.3)",
            },
          }}
        >
          <AttachFileOutlinedIcon
            sx={{
              color: "rgb(255, 255, 255)",
            }}
          />
        </Box> */}
        <input
          ref={textRef}
          value={message}
          onKeyPress={(e) => {
            let keyCode = e.keyCode || e.which;
            if (keyCode == 13) {
              if (send_message(message)) {
                setMessage('');
                setTimeout(() => {
                  messages_view.current.scrollTop = messages_view.current.scrollHeight;
                }, 100);
              }
            }
          }}
          onChange={(e)=>{
            setMessage(e.target.value);
          }}
          placeholder="Digite sua mensagem aqui..."
          style={{
            width: "100%",
            padding: "1rem",
            fontSize: "0.9rem",
            borderRadius: "1rem",
            border: "none",
            outline: "none",
            resize: "none",
            fontFamily: "inherit",
            backgroundColor: "var(--background-color1)",
            color: "var(--text-color0)",
          }}

        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "0.5rem",
            padding: "0.8rem",
            borderRadius: "1rem",
            backgroundColor: "rgba(var(--accent-color0), 0.4)",
            height: "max-content",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(var(--accent-color0), 0.5)",
            },
          }}
          onClick={()=>{
            if (send_message(message)) {
              setMessage('');
              setTimeout(() => {
                messages_view.current.scrollTop = messages_view.current.scrollHeight;
              }, 100);
            }
          }}
        >
          <SendRoundedIcon
            sx={{
              color: "rgb(255, 255, 255)",
            }}
          />
        </Box>
      </div>
    </div>
  );
}
