export const login = (data) => (
    {
        type: 'login',
        payload: data
    }
);

export const logout = () => (
    {
        type: 'login',
        payload: ''
    }
);


export const mudarUser = (data) => (
    {
        type: 'user',
        payload: data
    }
);

export const mudarLoadingHome = (data) => (
    {
        type: 'loading_home',
        payload: data
    }
);

export const mudarFaculdade = (data) => (
    {
        type: 'faculdade',
        payload: data
    }
);



export const mudarSocket = (data) => (
    {
        type: 'socket',
        payload: data
    }
);

export const mudarSala = (data) => (
    {
        type: 'sala',
        payload: data
    }
);

export const mudarProcesso = (data) => (
    {
        type: 'processo',
        payload: data
    }
);

export const mudarMensagens = (data) => (
    {
        type: 'mensagens',
        payload: data
    }
);



export const mudarSalaScreen = (data) => (
    {
        type: 'sala_screen_change',
        payload: data
    }
);



export const mudarChatOpen = (data) => (
    {
        type: 'chat_open_individual',
        payload: data
    }
);


export const changeShowCall = (data) => (
    {
        type: 'show_call',
        payload: data
    }
);


export const changeConnect = (data) => (
    {
        type: 'connect',
        payload: data
    }
);
