import {
  ChatText,
  Gear,
  Microphone,
  MicrophoneSlash,
  Screencast,
  UsersThree,
  VideoCamera,
  VideoCameraSlash,
} from "@phosphor-icons/react";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import BackHandIcon from "@mui/icons-material/BackHand";
import ControlButton from "../../../../components/ControlButtom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { NoteAlt, NoteAltOutlined } from "@mui/icons-material";

export default function VideoAreaBottomControlls({
  // streaming control
  localStream,
  enableVideoStream,
  handleEnableVideoStream,
  enableAudioStream,
  handleEnableAudioStream,
  isShareScreen,
  handleStartScreenShare,
  handleDisconnectScreenShare,
  handleDisconnect,
  show_info,
  setShowInfo,

  // right content control
  show_chat,
  setShowChat,
  show_participants,
  setShowParticipants,
  get_messages_from_student,
  id_candidato,
  students
}) {

  const [show_warning,setShowWarning] = useState(false);
  const user = useSelector(store=>store.AppReducer.user);
  useEffect(()=>{
    console.log('update here')
    
    console.log(students.filter((item)=>item.id==id_candidato)[0].counter)
    setShowWarning(students.filter((item)=>item.id==id_candidato)[0]?.counter==0?false:true)
  },[students])
  return (
    <div
      style={{
        height: "max-content",
        width: "100%",
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "1.4rem",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <div style={{
              padding: '0.1rem',
              height: 56,
              width: 56,
              objectFit: 'cover',
              borderRadius: '1rem',
              background:'orange',
              display:'flex',
              justifyContent:'center',
              alignItems:'center'
        }}>
          <p style={{marginBottom:0,textAlign:'center',color:'white'}}>{user?.name?.toUpperCase().substring(0,2)}</p>

        </div>
        {/* <ControlButton tooltip="Perfil" bgImage="https://i.pravatar.cc/300" /> */}
        {/* <ControlButton tooltip="Configurações" icon={<Gear weight="fill" />} /> */}
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <ControlButton
          tooltip={enableVideoStream ? "Desligar câmera" : "Ligar câmera"}
          icon={
            enableVideoStream ? (
              <VideoCamera weight="fill" />
            ) : (
              <VideoCameraSlash />
            )
          }
          onClick={() =>
            enableVideoStream
              ? handleEnableVideoStream(localStream.current, false)
              : handleEnableVideoStream(localStream.current, true)
          }
        />
        <ControlButton
          tooltip={enableAudioStream ? "Desligar microfone" : "Ligar microfone"}
          icon={
            enableAudioStream ? (
              <Microphone weight="fill" />
            ) : (
              <MicrophoneSlash />
            )
          }
          onClick={() =>
            enableAudioStream
              ? handleEnableAudioStream(localStream.current, false)
              : handleEnableAudioStream(localStream.current, true)
          }
        />
        <ControlButton
          // tooltip="Compartilhar tela"
          tooltip={
            isShareScreen ? "Desconectar compartilhamento" : "Compartilhar tela"
          }
          icon={
            isShareScreen ? (
              <Screencast weight="fill" />
            ) : (
              <Screencast weight="regular" />
            )
          }
          onClick={() =>
            isShareScreen
              ? handleDisconnectScreenShare()
              : handleStartScreenShare()
          }
        />
        {/* <ControlButton tooltip="Pedir a palavra" icon={<BackHandIcon />} /> */}
        <ControlButton
          tooltip="Sair da chamada"
          icon={
            <CallEndIcon
              sx={{
                color: "#fc3d3d",
              }}
            />
          }
          onClick={handleDisconnect}
        />
      </div>

      <div
        style={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <div style={{position:'relative'}}>

        <ControlButton
          tooltip={show_chat ? "Esconder chat" : "Mostrar chat"}
          icon={show_chat ? <ChatText weight="fill" /> : <ChatText />}
          onClick={() => {
            setShowInfo(false);
            if (show_participants) setShowParticipants(false);
            setShowChat(!show_chat);
            setShowWarning(false);

          }}
        />
        {show_warning && <div style={{width:20,height:20,background:'green',top:-5,right:-5,position:'absolute',borderRadius:100}}>

        </div>}
        </div>

        <ControlButton
          tooltip={
            show_participants
              ? "Esconder participantes"
              : "Mostrar participantes"
          }
          icon={
            show_participants ? <UsersThree weight="fill" /> : <UsersThree />
          }
          onClick={() => {
            setShowInfo(false);
            if (show_chat) setShowChat(false);
            setShowParticipants(!show_participants);
          }}
        />

<ControlButton
          tooltip={
              "Avaliar participante"
          }
          icon={
            show_info?<NoteAlt weight="fill" /> :
            <NoteAltOutlined weight="fill" />
          }
          onClick={() => {
            if (show_chat) setShowChat(false);
            if (show_participants) setShowParticipants(false);

            setShowInfo(!show_info);
            // if (show_chat) setShowChat(false);
            // setShowParticipants(!show_participants);
          }}
        />
      </div>
    </div>
  );
}
