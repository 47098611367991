import CustomInput from "../../components/CustomInput";
import form_styles from "../../components/SpecificStyles/form.module.css";
import PersonIcon from "@mui/icons-material/Person";
import { useEffect, useState } from "react";
import ControlButton from "../../components/ControlButtom";
import {
  Microphone,
  MicrophoneSlash,
  VideoCamera,
  VideoCameraSlash,
} from "@phosphor-icons/react";
import { useSelector } from "react-redux";

export default function UserInfoForm({
  roomName,
  handleRoomName,
  handleConnect,

  username,
  handleUsername,

  // media control
  handleStartMedia,
  localVideo,

  // streaming control
  localStream,
  enableVideoStream,
  handleEnableVideoStream,
  enableAudioStream,
  handleEnableAudioStream,
  student,
  closeCall,
  number_online,isStartMedia,
}) {
  useEffect(() => {
      handleStartMedia();
  }, []);
  
  const socket = useSelector(store=>store.AppReducer.socket);
  const user = useSelector(store=>store.AppReducer.user);
  console.log(student)
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        gap: "3rem",
        position:'fixed',
        top:0,
        left:0,
        right:0,
        bottom:0,
        background:'white',
        flexWrap: 'wrap',
        overflow:'scroll'
      }}
      className="white-mode"
    >
      {isStartMedia==true && <div style={{position:'absolute',top:50,right:50}}>
        <i className="mdi mdi-close" style={{fontSize:25,cursor:'pointer'}} onClick={()=>{
          closeCall();
        }}  ></i>

      </div>}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            backgroundColor: "var(--background-color1)",
            borderRadius: "0.6rem",
            overflow: "hidden",
            position: "relative",
            height: "20rem",
            justifyContent: "center",
            aspectRatio: "16/9",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "20rem",
              backgroundColor: "black",
              display: enableVideoStream ? "none" : "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1.4rem",
              padding: "0 6rem",
              color: "white",
              position: "absolute",
            }}
          >
            Câmera desligada
          </div>
          <video
            ref={localVideo}
            autoPlay
            style={{
              maxWidth: "100%",
              maxHeight: "20rem",
              objectFit: "contain",
              borderRadius:10,
            }}
          ></video>
        </div>

        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent:'center',
            alignItems:'center'
          }}
        >
          <ControlButton
            tooltip={enableVideoStream ? "Desligar câmera" : "Ligar câmera"}
            icon={
              enableVideoStream ? (
                <VideoCamera weight="fill" />
              ) : (
                <VideoCameraSlash />
              )
            }
            onClick={() =>
              enableVideoStream
                ? handleEnableVideoStream(localStream.current, false)
                : handleEnableVideoStream(localStream.current, true)
            }
          />
          <ControlButton
            tooltip={
              enableAudioStream ? "Desligar microfone" : "Ligar microfone"
            }
            icon={
              enableAudioStream ? (
                <Microphone weight="fill" />
              ) : (
                <MicrophoneSlash />
              )
            }
            onClick={() =>
              enableAudioStream
                ? handleEnableAudioStream(localStream.current, false)
                : handleEnableAudioStream(localStream.current, true)
            }
          />
        </div>
      </div>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
        className={form_styles.customFormStyle}
        onSubmit={(e) => {
          e.preventDefault();
          console.log('submit')
          handleConnect();
        }}
      >
        <p style={{fontSize:18,marginBottom:0}}><b>Informações do Avaliador:</b></p>
        <p style={{marginBottom:0}}><b>Nome:</b> {user?.name}</p>
        <p style={{fontSize:18,marginBottom:0}}><b>Informações do Candidato:</b></p>
        <p style={{marginBottom:0}}><b>Nome:</b> {student?.name}</p>
        <p style={{marginBottom:0}}><b>Número de Inscrição:</b> {student?.numero_inscricao}</p>
        <p style={{marginBottom:0}}><b>Sala:</b> Sala {student?.sala?.numero}</p>
        <p style={{marginBottom:0}}><b>Status:</b> {number_online.includes(student?.id?.toString())?<span style={{color:'green'}}>Online</span>:<span style={{color:'red'}}>Offline</span>}</p>
        


        {isStartMedia==true && <button type="submit"  className="btn btn-info btn-lg btn-outline btn-circle m-r-5" >Entrar na Chamada <i
            className="mdi mdi-call-made"
            style={{  color: 'white', fontStyle: 'normal' }}
            >
                </i></button>}

{isStartMedia==false &&<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>}

        
      </form>
    </div>
  );
}
