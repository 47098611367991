import React from 'react';
import Header from './header';

import { URL, URL_SOCKET, TURN_1, TURN_2, TURN_3, TURN_4 } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout, mudarProcesso, mudarSocket, mudarSalaScreen, mudarChatOpen, changeShowCall, changeConnect } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';
import io from 'socket.io-client';

import moment from 'moment-timezone';
import Chat from './Chat';
import ReportAction from './ReportAction';
import ChatIndividual from './ChatIndividual';
import ReactTooltip from 'react-tooltip';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import HeaderSala from './HeaderSala';
import { getStudents } from '../services/requests';
import SolicitacaoModal from './SolicitacaoModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import Search from '../ReisableComponents/Search';
import { CircularProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MeetingRoom from "./Meet";

class Sala extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: true,
            redirect: false,
            path: '/',
            salas: [],
            sala_select: {},
            number_online: [],
            comunicados: [],
            salas_ids: [],
            full_screen_open: false,
            aluno_fullscreen: -1,
            candidatos: [],
            width_candidato_box: 0,
            height_candidato_box: 0,
            alunoChat_select: {},
            candidato_selectAcao: {},
            show_modal_action: false,
            show_modal_chat_geral: false,
            show_modal_request: false,
            times_reconects: {},
            loading_sala: false,
            loading_alter_video: {},
            video_blink: {},
            video_blink_movement: {},
            number_line: [],
            requestSelect: null,
            message_warning: null,
            loadings_pause: {},
            candidatos: [],
            pagination: {
                page: 1,
                last_page: 1,
            },
            loading_search: false,
            id_candidato: -1,
            show_chat_meet: false,
            entrevistas: []

        }
        this.fullscreen_ref = React.createRef();
        this.audio_context_peers = {}
        this.list_peers = {};
        this.configuration = {
            iceServers: [
                { urls: "stun:stun.1.google.com:19302" },
                {
                    'urls': 'turn:turnserver.ontest.com.br:3478?transport=udp',
                    'credential': 'Coconuts69341228#',
                    'username': 'onteste_projetos'
                },
                // {
                //     'urls': 'turn:' + TURN_1 + ':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:' + TURN_2 + ':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:' + TURN_3 + ':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // },
                // {
                //     'urls': 'turn:' + TURN_4 + ':3478?transport=udp',
                //     'credential': 'Coconuts69341228#',
                //     'username': 'onteste_projetos'
                // }

            ],
            sdpSemantics: 'unified-plan'
        };
        this.unmont = false;
        this.good_quality_student = {};
        this.timeout_request = {}
        this.ref_messages = React.createRef();
        // this.videoRef=null;
    }
    handle_offer_sdp(offer) {
        let sdp = offer.sdp.split('\r\n');//convert to an concatenable array
        let new_sdp = '';
        let position = null;
        sdp = sdp.slice(0, -1); //remove the last comma ','
        for (let i = 0; i < sdp.length; i++) {//look if exists already a b=AS:XXX line
            if (sdp[i].match(/b=AS:/)) {
                position = i; //mark the position
            }
        }
        if (position) {
            sdp.splice(position, 1);//remove if exists
        }
        for (let i = 0; i < sdp.length; i++) {
            if (sdp[i].match(/m=video/)) {//modify and add the new lines for video
                new_sdp += sdp[i] + '\r\n' + 'b=AS:' + '24' + '\r\n';
            }
            else {
                if (sdp[i].match(/m=audio/)) { //modify and add the new lines for audio
                    new_sdp += sdp[i] + '\r\n' + 'b=AS:' + 48 + '\r\n';
                }
                else {
                    new_sdp += sdp[i] + '\r\n';
                }
            }
        }
        return new_sdp; //return the new sdp
    }
    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    componentDidMount() {
        this.unmont = false;

        // document.getElementById('header_page').classList.add('mouse_come_here');
        // this.mouseEvent = (function (e) {
        //     if (e.clientY < 70 && this.props.chat_open == false) {
        //         document.getElementById('header_page').classList.add('visible');
        //         document.getElementById('header_page').classList.remove('mouse_come_here');
        //     }
        //     else {
        //         document.getElementById('header_page').classList.remove('visible');
        //         document.getElementById('header_page').classList.add('mouse_come_here');
        //     }
        // }).bind(this);
        // window.addEventListener('mousemove', this.mouseEvent);



        // this.props.mudarSalaScreen(true);
        this.setState({ loading_screen: true });
        this.get_candidatos(this.props.match.params.id, 1);

    }

    componentDidUpdate(props, state) {
        // if (props.chat_open != this.props.chat_open) {
        //     let width_candidato_box = window.innerWidth / 6;
        //     if (this.props.chat_open != -1) {
        //         width_candidato_box = (window.innerWidth - 300) / 6;
        //     }
        //     let height_candidato_box = (window.innerHeight - 0) / 4;
        //     this.setState({ width_candidato_box, height_candidato_box });
        // }



    }

    send_message(texto) {
        let uid_msg = this.uuidv4();
        let msg = texto;

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            let alunos = [...this.state.candidatos];
            let aluno = alunos.filter((item) => item.id == this.state.id_candidato)[0];

            this.props.socket.emit("send_msg", { processo_id: this.props.processo.id, avaliador: true, msg: msg, sala_id: aluno.sala.id, aluno_id: aluno.id, nome_chat: this.props.user.name, id_msg: uid_msg, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id, id_adm: this.props.user.id });
            // $(this).val('');
            // $('#body_msg').append(template_send.replace(/%MSG%/g,msg).replace(/%ID%/g,uid_msg));
            // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);

            // let aluno_select={...this.state.aluno_select};
            // aluno_select.mensagens.push({
            //     sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
            //     receive_id: this.state.aluno_select.id, sala_id:this.state.aluno_select.sala_id, uuid: uid_msg, visto: false, message: msg
            // });
            aluno.mensagens.push({
                sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
                receive_id: aluno.id, sala_id: aluno.sala.id, uuid: uid_msg, visto: undefined, message: msg,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')

            });
            // this.setState({ texto: '' });
            alunos = alunos.map((item) => {
                if (item.id == aluno.id) {
                    return aluno;
                }
                return item;
            })
            this.setState({ candidatos: [...alunos] });
            return true;

        }
        return false
    }

    componentWillUnmount() {
        console.log('DESMONTOU')
        // window.removeEventListener('mousemove', this.mouseEvent)

        this.props.mudarSalaScreen(false);

        clearTimeout(this.logs_timeout);
        clearTimeout(this.timeout_numbers);
        if (this.socket != undefined) {
            this.socket.close();
            console.log('socket null')
        }
        this.props.mudarSocket(null);
        this.unmont = true;

    }

    async get_candidatos(id, page = 1, search = '') {
        getStudents(id, this.props.token, page, search).then(async (responseLog) => {
            console.log(this.unmont)
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);
                    setTimeout(() => {
                        this.get_candidatos(this.props.match.params.id, page, search);
                    }, 5000);
                }
                else if (this.unmont == false) {
                    console.log('ENTROU')
                    this.props.mudarProcesso(resp.processo);
                    let salas_ids = [];
                    let candidatos = [];
                    let comunicados = [];
                    for (let i = 0; i < resp.comunicados.length; i++) {
                        resp.comunicados[i].send = true;
                        comunicados.push(resp.comunicados[i]);
                    }


                    this.setState({ candidatos: resp.candidatos,entrevistas:resp.entrevistas, loading_search: false, loading_screen: false, comunicados: comunicados }, () => {
                        clearTimeout(this.timeout_numbers);
                        this.get_numbers();
                    });
                    this.video_init();
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_candidatos(this.props.match.params.id, page, search);
                }, 5000);

            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_candidatos(this.props.match.params.id, page, search);
                }, 5000);
            });
    }





    sort_by_online(a, b) {
        let number_online = [...this.state.number_online];
        // console.log(a.id+' '+b.id)
        // console.log(number_online.includes(a.id.toString()))
        // console.log(number_online.includes(b.id.toString()))
        // console.log(number_online.includes(a.id.toString()) < number_online.includes(b.id.toString()))
        // console.log(number_online.includes(b.id.toString()) > number_online.includes(a.id.toString()))
        // console.log(number_online)


        if (number_online.includes(a.id.toString()) > number_online.includes(b.id.toString())) {
            return -1;
        }
        if (number_online.includes(b.id.toString()) < number_online.includes(a.id.toString())) {
            return 1;
        }
        return 0;
    }

    draw_peer(id, uuid) {
        let user_id = id;
        if (this.audio_context_peers[user_id]['id'] != uuid) {
            return;
        }
        if (this.audio_context_peers[user_id]['context'].state != 'running') {
            this.audio_context_peers[user_id]['context'].resume();
        }

        if (
            this.state.sala_select.alunos[id]
        ) {

            let bar1 = this.state.sala_select.alunos[id].bar1.current;
            let bar2 = this.state.sala_select.alunos[id].bar2.current;
            let bar3 = this.state.sala_select.alunos[id].bar3.current;
            // console.log(this.state.sala_select.alunos[id])
            // let bar_peer = document.getElementById('indicator'+id);
            // let audio_elem = document.getElementById('audio' + id);

            if (bar1 == null || bar2 == null || bar3 == null || !this.state.number_online.includes(id.toString())) {
                // console.log('encerrou ' + id)
                clearTimeout(this.audio_context_peers[id].timeout);
                if (bar1 != null) {
                    // console.log('hidden')
                    // bar1.hidden = true;
                    // bar2.hidden = true;
                    // bar3.hidden = true;
                    if (this.state.sala_select.alunos[id].bar1 != undefined && this.state.sala_select.alunos[id].bar1.current != null) {
                        this.state.sala_select.alunos[id].bar1.current.hidden = true;
                    }
                    if (this.state.sala_select.alunos[id].bar2 != undefined && this.state.sala_select.alunos[id].bar2.current != null) {
                        this.state.sala_select.alunos[id].bar2.current.hidden = true;
                    }
                    if (this.state.sala_select.alunos[id].bar3 != undefined && this.state.sala_select.alunos[id].bar3.current != null) {
                        this.state.sala_select.alunos[id].bar3.current.hidden = true;
                    }
                }

                return 0;
            }
            // var drawVisual = requestAnimationFrame(()=>draw_peer(id));
            this.audio_context_peers[id]['analyser'].getByteTimeDomainData(this.audio_context_peers[id]['dataArray']);

            var sliceWidth = 300 * 1.0 / this.audio_context_peers[id]['bufferLength'];
            var x = 0;
            let max = 0;

            for (var i = 0; i < this.audio_context_peers[id]['bufferLength']; i++) {
                var v = this.audio_context_peers[id]['dataArray'][i] / 128.0;
                if (((v - 0.93) * 100) > 10) {
                    bar1.hidden = false;
                    bar2.hidden = false;
                    bar3.hidden = false;
                    this.audio_context_peers[id].isInTime = true;
                    clearTimeout(this.audio_context_peers[id].timeout);
                    this.audio_context_peers[id].timeout = setTimeout(() => {
                        this.audio_context_peers[id].isInTime = false;
                        let bar1 = this.state.sala_select.alunos[id]?.bar1?.current;
                        let bar2 = this.state.sala_select.alunos[id]?.bar2?.current;
                        let bar3 = this.state.sala_select.alunos[id]?.bar3?.current;
                        if (bar1 != null) {
                            bar1.hidden = true;
                            bar2.hidden = true;
                            bar3.hidden = true;
                        }
                    }, 10000);
                }
                // else{
                //     bar1[0].hidden=true;
                //     bar2[0].hidden=true;
                //     bar3[0].hidden=true;
                // }
                // if((v-1)>0)
                //     bar_peer.style.clipPath='circle(40%)';
                // else
                //     bar_peer.style.clipPath='circle(30%)';


                if (bar1 != null && bar2 != null && bar3 != null) {
                    // for (let j = 0; j < bar1.length; j++) {
                    //     bar1[j].style.height = bar2[0].style.height;
                    // }
                    bar1.style.height = bar2.style.height;
                    bar2.style.height = bar3.style.height;
                    if (((v - 0.93) * 100) > 15) {
                        bar3.style.height = '15px';
                    }
                    else {
                        bar3.style.height = ((v - 0.93) * 100) + 'px';
                    }

                    if (((v - 0.93) * 100) > max) {
                        max = ((v - 0.93) * 100);
                    }
                }

            }
            setTimeout(() => {
                this.draw_peer(id, uuid)
            }, 300);
        }


    };

    get_messages_from_student(id) {
        if (id == undefined) {
            id = this.state.id_candidato
        }
        this.ref_messages.current = [...this.state.candidatos.filter((item) => item.id == id)[0].mensagens];
        console.log('function called', [...this.state.candidatos.filter((item) => item.id == id)[0].mensagens].length)
        return [...this.state.candidatos.filter((item) => item.id == id)[0].mensagens];
    }


    salvar_log() {
        fetch(`${URL}api/avaliador/salvar_log?screen=sala`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {
                    if (this.unmont == false) {
                        this.logs_timeout = setTimeout(() => {
                            this.salvar_log();
                        }, 30000);
                    }
                }
            } catch (err) {
                console.log(err);
                if (this.unmont == false) {
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }

            }

        })
            .catch((err) => {
                console.log(err);
                if (this.unmont == false) {
                    this.logs_timeout = setTimeout(() => {
                        this.salvar_log();
                    }, 30000);
                }
            });
    }

    onIceCandidate(peer, evt, aluno_id) {
        if (evt.candidate) {
            this.socket.emit("candidate_to_aluno", JSON.stringify({ "candidate": evt.candidate, avaliador: true, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id, aluno_id: aluno_id }));
        }
    }

    video_init() {
        this.admin_id = this.uuidv4();
        console.log('connecting')
        this.socket = io.connect(URL_SOCKET, { "transports": ['websocket'], query: { token: this.props.token } });
        console.log('connected')

        this.props.mudarSocket(this.socket);

        // socket.emit("join", {name:'Admin',sala_id:sala_id,faculdade_id:faculdade_id});
        this.socket.on("connect", function (client) {
            // this.setState({connect:true});
            this.props.changeConnect(true);
            // this.socket.emit('join_stream_admin', { sala_id: this.state.sala_select.id, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id })
            this.socket.emit("join", { avaliador: true, name: 'Avaliador', processo_id: this.props.processo.id, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id });

        }.bind(this));



        this.socket.on("candidate", function (msg) {
            // console.log("candidate received");
            if (this.list_peers[JSON.parse(msg).aluno_id] != undefined && this.list_peers[JSON.parse(msg).aluno_id].connectionState != 'closed') {
                this.list_peers[JSON.parse(msg).aluno_id].addIceCandidate(new RTCIceCandidate(JSON.parse(msg).candidate));
            }

        }.bind(this));


        this.socket.on("sdp" + this.admin_id, function (msg) {
            console.log("sdp received");
            let id = JSON.parse(msg).aluno_id;
            if (this.list_peers[id] != undefined) {
                this.list_peers[id].close();
            }
            this.list_peers[id] = new RTCPeerConnection(this.configuration);

            this.list_peers[id].onicecandidate = function (evt) {
                if (!evt.candidate) return;
                // console.log("onicecandidate called");
                this.onIceCandidate(this.list_peers[id], evt, id);
            }.bind(this);

            this.list_peers[id].onaddstream = function (evt) {
                console.log("onaddstream called");
                if (this.state.sala_select.alunos[id] != undefined) {

                    this.state.sala_select.alunos[id].videoRef.current.srcObject = evt.stream;
                    this.state.sala_select.alunos[id].stream = evt.stream;
                    let user_id = id;
                    this.audio_context_peers[user_id] = {}
                    this.audio_context_peers[user_id]['context'] = new AudioContext();
                    this.audio_context_peers[user_id]['analyser'] = this.audio_context_peers[user_id]['context'].createAnalyser();
                    this.audio_context_peers[user_id]['frequencyData'] = null;
                    this.audio_context_peers[user_id]['source'] = this.audio_context_peers[user_id]['context'].createMediaStreamSource(this.state.sala_select.alunos[id].stream);
                    this.audio_context_peers[user_id]['source'].connect(this.audio_context_peers[user_id]['analyser']);
                    this.audio_context_peers[user_id]['gainNode'] = this.audio_context_peers[user_id]['context'].createGain();
                    this.audio_context_peers[user_id]['gainNode'].gain.value = 0.1; // setting it to 10%
                    this.audio_context_peers[user_id]['gainNode'].connect(this.audio_context_peers[user_id]['context'].destination);
                    // analyser.connect(context.destination);

                    this.audio_context_peers[user_id]['context'].resume()
                    this.audio_context_peers[user_id]['analyser'].fftSize = 32;
                    this.audio_context_peers[user_id]['bufferLength'] = this.audio_context_peers[user_id]['analyser'].frequencyBinCount;
                    this.audio_context_peers[user_id]['dataArray'] = new Uint8Array(this.audio_context_peers[user_id]['bufferLength']);
                    this.audio_context_peers[user_id]['analyser'].getByteTimeDomainData(this.audio_context_peers[user_id]['dataArray']);
                    this.audio_context_peers[user_id].id = this.uuidv4()
                    // this.audio.srcObject = evt.stream
                    this.draw_peer(user_id, this.audio_context_peers[user_id].id);

                    console.log('full_screen_added1')
                    // console.log(this.state.aluno_fullscreen,Object.values(this.state.sala_select.alunos)[this.state.aluno_fullscreen]?.id,id)
                    if (this.state.aluno_fullscreen != -1 && this.state.aluno_fullscreen == id) {
                        console.log('full_screen_added2')
                        console.log(evt.stream)
                        this.fullscreen_ref.current.srcObject = evt.stream;
                    }
                }
                // document.getElementById("video_tag" + JSON.parse(msg).aluno_id).srcObject = evt.stream;
            }.bind(this);
            // list_peers[id].oniceconnectionstatechange = function() {
            //     console.log(list_peers[id].iceConnectionState);
            // }
            // list_peers[id].onconnectionstatechange = function() {
            //     console.log(list_peers[id].connectionState);
            // }   
            var sessionDesc = new RTCSessionDescription(JSON.parse(msg).sdp);
            this.list_peers[id].setRemoteDescription(sessionDesc);
            this.list_peers[id].createAnswer().then(function (sdp) {
                if (this.good_quality_student[id] != true) {
                    sdp.sdp = this.handle_offer_sdp(sdp)
                }
                else {
                    console.log('NAO ENTROU SDP')
                }
                console.log('anwser sdp');
                this.list_peers[id].setLocalDescription(new RTCSessionDescription(sdp));
                this.socket.emit("answer", JSON.stringify({ "sdp": sdp, admin_id: this.admin_id, avaliador: true, aluno_id: JSON.parse(msg).aluno_id, faculdade_id: this.props.faculdade.id }));
            }.bind(this)).catch(function (e) {
                console.log(e);
            });
        }.bind(this));



        this.socket.on("aluno_desconectou_" + this.props.faculdade.id, function (data) {
            // console.log(video);
            if (data.aluno_id != undefined) {
                let aluno = this.state.candidatos.filter((item, id) => item.id == data.aluno_id)[0]
                if (aluno != undefined) {


                    this.setState({ number_online: data.number })

                }
                else {
                    this.setState({ number_online: data.number })
                }
            }
            else {
                this.setState({ number_online: data.number })
            }
        }.bind(this))


        this.socket.on("aluno_conectou_" + this.props.faculdade.id, function (data) {
            console.log(data);
            // $('#online_number').text(data.number);
            if (data.aluno_id != undefined) {
                let aluno = this.state.candidatos.filter((item, id) => item.id == data.aluno_id)[0]

                if (aluno != undefined) {

                    this.setState({ number_online: data.number })
                }
                else {
                    this.setState({ number_online: data.number })
                }

            }
            else {
                this.setState({ number_online: data.number })
            }
        }.bind(this))

        this.socket.on('disconnect', function () {
            console.log('desconectou socket')
            this.props.changeConnect(false);

            // this.setState({connect:false});
        }.bind(this));

        //Send the ICE Candidate to the remote peer
        clearTimeout(this.timeout_numbers);
        this.timeout_numbers = setTimeout(() => {
            this.get_numbers();
        }, 300000);

    }

    get_numbers() {
        if (this.socket != null) {
            this.socket.emit("get_numbers", { avaliador: true, faculdade_id: this.props.faculdade.id, admin_id: this.admin_id, sala_id: null });
        }
        clearTimeout(this.timeout_numbers);
        this.timeout_numbers = setTimeout(() => {
            this.get_numbers();
        }, 300000);
    }

    receive_all(uuid, sala_id, aluno_id) {
        fetch(`${URL}api/avaliador/receive_all`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uuid,
                sala_id, aluno_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    sendReceive(aluno) {
        let aluno2 = aluno;
        if (aluno2.mensagens.length > 0) {
            this.props.socket.emit('msg_receive_aluno', { avaliador: true, sala_id: aluno2.sala.id, aluno_id: aluno2.id, uuid: aluno2.mensagens[aluno2.mensagens.length - 1].uuid, faculdade_id: this.props.faculdade.id });
            this.receive_all(aluno2.mensagens[aluno2.mensagens.length - 1].uuid, aluno2.sala.id, aluno2.id);
        }
        aluno2.counter = 0;
        let candidatos = this.state.candidatos.map((item) => {
            if (item.id != aluno2.id) {
                return item;
            }
            else {
                return aluno2;
            }
        });
        this.setState({ candidatos: candidatos, id_candidato: aluno.id });
    }



    render() {
        console.log(this.good_quality_student)
        return (
            <div>
                {/* <HeaderSala showChatGeral={(e) => {
                    this.setState({ show_modal_chat_geral: e });
                }} /> */}

                {this.state.redirect == true && <Redirect to={this.state.path} />}

                {this.state.loading_screen == false && <div className="row">
                    {/* <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>Nome do Fiscal: {this.props.user.name}</p>

                                <h4 style={{ color: 'black' }}>Comunicado Geral</h4>


                                <div className="d-flex" style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
                                    <textarea id="msg_comunicado" placeholder="Escreva um comunicado Geral para enviar aos candidatos" className="form-control" style={{ resize: 'none', height: '100px' }} />
                                    <button className="btn btn-primary" id="enviar_comunicado">
                                        Enviar
                                        </button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="card" style={{ marginBottom: 0 }}>
                            <div className="card-body" style={{ padding: 0 }}>
                                <div className="row" style={{ padding: '1.25rem' }}>

                                    <div className="col-12" style={{ marginBottom: '1rem' }}>
                                        <div style={{ padding: '1.25rem', position: 'absolute', right: 0 }}>

                                            {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <button onClick={() => {
                                                this.setState({ id_candidato: 'comunicado' });
                                                this.props.mudarChatOpen(0);
                                            }} className="btn btn-info btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-chat"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Comunicado geral</i></button>}

                                            {/* {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && this.props.processo.chat_type != 'none' && <button onClick={() => {
                                                this.setState({ show_modal_chat_geral: true });
                                            }} className="btn btn-success btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-chat"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Chat Geral</i></button>} */}
                                            <Link onClick={() => {
                                                let keys = Object.keys(this.list_peers);
                                                for (let i = 0; i < keys.length; i++) {
                                                    this.list_peers[keys[i]].close();
                                                }

                                            }} to='/' className="btn btn-secondary btn-outline btn-circle m-r-5" ><i
                                                className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Voltar</i></Link>


                                        </div>


                                        {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && <h4 style={{ color: 'black', fontWeight: 'bold' }}>{this.props.processo.name}</h4>}

                                        {/* {this.props.processo.type_fiscalization != 'all' && <h4 style={{ color: 'black' }}>{this.props.processo.name}</h4>} */}


                                        <div className="m-b-5">
                                            <span style={{ color: 'green', fontSize: 16 }}>Total de candidatos online: <span id="online_number">{this.state.number_online.length}</span></span>
                                        </div>





                                    </div>
                                    <div className='col-12'>
                                    <hr />
                                {this.state.entrevistas.length!=0 && <h4 style={{marginBottom:20}}>Informações da Avaliação</h4>}
                                {this.state.entrevistas.map((item, id) =>
                                    <div key={id}>
                                        <div style={{ marginTop: '1rem' }}>
                                            <h5 className="mt-0 header-title" style={{ fontSize: '1.5rem', textAlign: 'center' }}>
                                                {item.name}</h5>
                                            <p style={{ whiteSpace: 'pre-line' }}><b style={{ color: 'black', fontSize: 18 }}>Descrição:</b> <div dangerouslySetInnerHTML={{ __html: item.descricao }}></div></p>
                                            <div style={{ marginTop: 10 }}>
                                                {item.topicos.map((item2, id2) => (
                                                    <div key={id2} style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}><div style={{ background: 'black', borderRadius: 100, minWidth: 6, minHeight: 6, marginRight: 5 }}></div> <span>{item2.text}</span></div>
                                                ))}
                                            </div>

                                            <p style={{}}><b style={{ color: 'black', fontSize: 18,marginTop:10 }}>Critérios:</b> </p>

                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table className="table table-striped">
                                                        <thead>
                                                            <tr>

                                                                <th>Código do Critério</th>
                                                                <th>Texto do Critério</th>
                                                                <th>Nota mínima</th>
                                                                <th>Nota máxima</th>
                                                            </tr>

                                                        </thead>
                                                        <tbody >

                                                            {item.criterios.map((item2, id) => (
                                                                <tr key={id}>
                                                                    <td>
                                                                        {item2.code}

                                                                    </td>
                                                                    <td>
                                                                        {item2.text}

                                                                    </td>
                                                                    <td>
                                                                        {item2.min_score}

                                                                    </td>
                                                                    <td>
                                                                        {item2.max_score}

                                                                    </td>
                                                                </tr>
                                                            ))}

                                                           



                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>



                                            <hr />
                                        </div>
                                    </div>
                                )}
                                    </div>
                                </div>
                               

                                {this.state.loading_sala == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div></div>}







                                <div className="row" style={{ padding: '1.25rem' }}>

                                    <div className="col-12">

                                    {this.state.entrevistas.length!=0 && <h4 style={{marginBottom:20}}>Informações dos Candidatos</h4>}

                                        {/* <hr /> */}
                                        {this.state.loading_screen == false && <div>

                                            {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                                            {/* <br /> */}

                                            <div className="flex jcsb flex-wrap mb-4">

                                                <Search placeholder={"Pesquisar pelo nome do candidato ou número de inscrição"} ValueChange={value => {
                                                    clearTimeout(this.pesquisarTimeout);
                                                    this.pesquisarTimeout = setTimeout(() => {
                                                        let page = { ...this.state.pagination };
                                                        page.current_page = 1;
                                                        this.setState({ pagination: page, search: value, loading_search: true });
                                                        this.get_candidatos(this.props.match.params.id, 1, value);
                                                    }, 500);
                                                }} />



                                            </div>

                                            <div className="table-rep-plugin">
                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                    <table id="tech-companies-1" className="table table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <th>CPF</th>
                                                                <th>Número de inscrição</th>
                                                                <th>Sala</th>
                                                                {<th>Status</th>}
                                                                <th>Data/Hora da avaliação</th>
                                                                <th>Fez avaliação oral?</th>
                                                                <th data-priority="3">Ações</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.loading_search == false && this.state.candidatos.map((item, id) => (

                                                                <tr key={id}>
                                                                    <td >{item.name}</td>
                                                                    <td >{item.cpf}</td>
                                                                    <td >{item.numero_inscricao}</td>
                                                                    <td >Sala {item.sala.numero}</td>
                                                                    {this.state.number_online.includes(item.id.toString()) ? <td style={{ color: 'green' }}>Online</td> : <td style={{ color: 'red' }}>Offline</td>}
                                                                    <td>{item.exams_oral == null ? '-' : (
                                                item.exams_oral.datetime_initial != null ?
                                                    (<>
                                                        <p style={{ marginBottom: 0 }}><b>Inicio:</b> {moment(item.exams_oral.datetime_initial).format('DD/MM/YYYY HH:mm')}</p>
                                                        <p style={{ marginBottom: 0 }}><b>Fim:</b> {moment(item.exams_oral.datetime_end).format('DD/MM/YYYY HH:mm')}</p>
                                                    </>) : '-'
                                            )}</td>
                                            <td>{(item.exams_oral == null || item.exams_oral.finish == false) ? 'Não' : 'Sim'}</td>



                                                                    {/* <td>Questão {id + 1}</td> */}



                                                                    <td>
                                                                        {<span
                                                                            onClick={() => {
                                                                                this.setState({ id_candidato: item.id }, () => {
                                                                                    console.log(this.state.id_candidato)
                                                                                    this.props.changeShowCall(true);
                                                                                });
                                                                            }}
                                                                            style={{ cursor: 'pointer', position: 'relative' }}

                                                                            className="btn btn-warning btn-outline btn-circle m-r-5"
                                                                            data-toggle="tooltip"
                                                                            data-tip={"Entrar em chamada com o candidato " + item.name + ""}
                                                                        >
                                                                            <ReactTooltip />
                                                                            <i className="mdi mdi-call-made"></i></span>}
                                                                        {<span onClick={(e) => {
                                                                            // e.stopPropagation();
                                                                            if (this.state.loading_sala === true) {
                                                                                return;
                                                                            }

                                                                            this.sendReceive(item);

                                                                            this.props.mudarChatOpen(0);
                                                                        }} className="btn btn-secondary btn-outline btn-circle m-r-5"
                                                                            data-toggle="tooltip"
                                                                            style={{ cursor: 'pointer', position: 'relative' }}
                                                                            data-tip={"Abrir o chat com o candidato " + item.name + ""}
                                                                        >
                                                                            <ReactTooltip />
                                                                            {item.counter > 0 && <div style={{
                                                                                // padding: '2px',
                                                                                // paddingTop: '3px',
                                                                                // height:15,
                                                                                minWidth: 20,
                                                                                background: 'rgb(75, 181, 67)',
                                                                                borderRadius: '50px',
                                                                                position: 'absolute',
                                                                                top: -5,
                                                                                textAlign: 'center',
                                                                                // paddingBottom: '3px',
                                                                                right: -5
                                                                            }}>

                                                                                <span style={{ color: 'white', textAlign: 'center', fontSize: 11 }}>{item.counter}</span>
                                                                            </div>}
                                                                            <i className="mdi mdi-chat"></i></span>}

                                                                    </td>

                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>

                                                </div>
                                            </div>
                                            {this.state.loading_search == true &&

                                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                                    <CircularProgress />
                                                </div></div>

                                            }
                                            {this.state.loading_search == false && this.state.candidatos.length == 0 && <div className="row w-100">
                                                <div className="col-12">
                                                    <p style={{ textAlign: 'center' }}>Nenhum candidato encontrado</p>

                                                </div>
                                            </div>}

                                            <Pagination
                                                className={(this.state.pagination.last_page == 1 ? "hide" : "")}
                                                style={{ display: "flex", justifyContent: "flex-end" }}
                                                count={this.state.pagination.last_page}
                                                value={this.state.pagination.current_page}
                                                onChange={(_, value) => {
                                                    console.log(_, value);
                                                    let page = { ...this.state.pagination };
                                                    page.current_page = value;
                                                    this.setState({ pagination: page });
                                                    this.get_candidatos(this.props.match.params.id, value, this.state.search);
                                                }}
                                            />

                                            {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                                        </div>}

                                        {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                            {/* <div className="spinner-border text-primary" role="status"  >
</div> */}
                                            <CircularProgress />
                                        </div></div>}




                                    </div>

                                </div>


                                <div className="row" style={{ margin: 0 }}>


                                    {(this.props.processo.type_fiscalization == 'all' || this.props.processo.type_fiscalization == 'image') && <div className="col-12 " hidden={this.state.show_modal_chat_geral == false} style={{
                                        position: 'fixed',
                                        right: 0,
                                        bottom: 0,
                                        left: 0,
                                        top: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // background: 'rgba(0,0,0,0.4)',
                                        zIndex: 2000
                                    }}>


                                        {this.props.socket != null && <div style={{ zIndex: 3000, width: '100%' }}> <Chat closeChat={() => {
                                            this.setState({ show_modal_chat_geral: false });
                                        }}
                                            show_chat_meet={this.state.show_chat_meet}
                                            id_candidato={this.state.id_candidato}
                                            clearTimeout={(id) => {
                                                let loadings_pause = { ...this.props.loadings_pause }
                                                loadings_pause[id] = false;
                                                clearTimeout(this.timeout_request[id])
                                                let additional = {}
                                                if (this.state.requestSelect?.id == id) {
                                                    additional.show_modal_request = false;
                                                }
                                                this.setState({ loadings_pause, ...additional })
                                            }}
                                            video_blink={this.state.video_blink} video_blink_movement={this.state.video_blink_movement} show_modal_chat_geral={this.state.show_modal_chat_geral} online={this.state.number_online} alunos={this.state.candidatos} admin_id={this.admin_id} comunicados={this.state.comunicados} mudarState={(data) => {
                                                let state = {}
                                                if (data.alunos != undefined) state.candidatos = data.alunos;
                                                if (data.comunicados != undefined) state.comunicados = data.comunicados;


                                                this.setState(state);

                                            }} /></div>}

                                        <div onClick={() => {
                                            this.setState({ show_modal_chat_geral: false });
                                        }}
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                                top: 0,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                background: 'rgba(0,0,0,0.4)',
                                                zIndex: 2000
                                            }}
                                        >
                                        </div>

                                    </div>}
                                    {this.props.socket != null && this.props.processo.chat_type != 'none' && <div className={this.props.chat_open != -1 ? 'chat_individual_open' : 'chat_individual_close'} style={{ position: 'fixed', zIndex: 1000, maxWidth: 400, top: 0, bottom: 0, width: 300 }}>
                                        <ChatIndividual
                                            send_message={(m) => this.send_message(m)}
                                            id_candidato={this.state.id_candidato}
                                            loadings_pause={this.state.loadings_pause}
                                            sendReceive={() => {
                                                let aluno = this.state.candidatos.filter((item) => item.id == this.state.id_candidato)[0];
                                                if (aluno != undefined) {
                                                    this.sendReceive(aluno);
                                                }
                                            }}
                                            chat_open={this.props.chat_open} online={this.state.number_online} alunos={this.state.candidatos} admin_id={this.admin_id} comunicados={this.state.comunicados} mudarState={(data) => {
                                                let state = {}
                                                if (data.alunos != undefined) state.candidatos = data.alunos;
                                                if (data.comunicados != undefined) state.comunicados = data.comunicados;

                                                this.setState(state);

                                            }} />
                                    </div>}

                                    {/* <div className="col-12 col-md-6 d-flex justify-content-center">
                                        <ReportAction setState={(e) => {
                                            this.setState(e);
                                        }} show_modal_action={this.state.show_modal_action} candidatos={this.state.candidatos}  candidato_select={this.state.candidato_selectAcao} />
                                    </div>
                                    <SolicitacaoModal
                                        loadings_pause={this.state.loadings_pause}

                                        sendRequestResponse={(data) => {
                                            let loadings_pause = { ...this.props.loadings_pause }
                                            loadings_pause[data.id] = true;
                                            this.setState({ loadings_pause })
                                            console.log('request dada', data)
                                            this.props.socket.emit('request_pause_response', { ...data });
                                            this.timeout_request[data.id] = setTimeout(() => {
                                                let loadings_pause = { ...this.props.loadings_pause }
                                                loadings_pause[data.id] = false;
                                                this.setState({ loadings_pause, message_warning: 'Não foi possível responder a solicitação do candidato ' + data.name + ' da Sala ' + data.sala_numero });
                                            }, 10000);
                                        }}
                                        request={this.state.requestSelect} setState={(e) => {
                                            this.setState(e);
                                        }} show_modal_request={this.state.show_modal_request}   /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <SweetAlert
                        warning
                        title={'Aviso!'}
                        onConfirm={() => this.setState({ message_warning: null })}
                        show={this.state.message_warning != null}
                        confirmBtnText='Entendi'
                        confirmBtnBsStyle="danger"
                    >
                        {this.state.message_warning}
                    </SweetAlert>
                    {this.props.show_call && <MeetingRoom
                    changeStudent={(candidato)=>{
                        let candidatos = this.state.candidatos.map((item)=>{
                            if(item.id==candidato.id){
                                return candidato;
                            }
                            return item;
                        })
                        this.setState({candidatos});
                    }}
                    entrevistas={this.state.entrevistas}
                        changeShowChatMeet={(e) => {
                            this.setState({ show_chat_meet: e });
                            if (e == true) {
                                let aluno = this.state.candidatos.filter((item) => item.id == this.state.id_candidato)[0];
                                if (aluno != undefined) {
                                    this.sendReceive(aluno);
                                }
                            }
                        }}
                        send_message={(m) => this.send_message(m)}
                        getAdminId={() => this.admin_id} processo={this.props.processo} number_online={this.state.number_online} closeCall={() => {
                            this.props.changeShowCall(false);
                            this.setState({ id_candidato: -1 })
                        }}
                        students={[...this.state.candidatos]}
                        get_messages_from_student={(id) => this.get_messages_from_student(id)}
                        id_candidato={this.state.id_candidato}
                        student={this.state.candidatos.filter((item) => item.id == this.state.id_candidato)[0]} />}

                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
        chat_open: state.AppReducer.chat_open,
        show_call: state.AppReducer.show_call,

    }
);

export default connect(mapsStateToProps, { changeConnect, changeShowCall, mudarUser, logout, mudarProcesso, mudarSocket, mudarSalaScreen, mudarChatOpen })(Sala);
