
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout, mudarMensagens, mudarChatOpen } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';

class ChatIndividual extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            texto: '',
            chat_open: -1,
            alunos: {},
            comunicados: [],
            name_filter: '', tab: 0
        };
        this.messages_view = React.createRef();
        this.requests_view = React.createRef();

    }

    componentDidUpdate(props) {
        if (this.props.id_candidato != props.id_candidato ) {
                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;

        }

        if (this.props.chat_open != props.chat_open) {
            this.setState({tab:this.props.chat_open==-1?0:this.props.chat_open})
            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
            this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;

        }

        
    }
    

    listen_channels(){
        this.props.socket.on("send_msg_avaliador", function (data) {
            console.log(data);
            if (data.admin_id != this.admin_id) {
                if (this.props.id_candidato == data.aluno_id) {
                    setTimeout(() => {
                        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                    }, 100);
                }
            }
        }.bind(this));
    }


    componentDidMount() {

        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;

        this.admin_id = this.props.admin_id;

        this.listen_channels();

        

        // let aluno_select={};
        // if(this.props.user.role=='fiscal_coordenador'){
        //     aluno_select =  { id: 'comunicado', name: 'Comunicado Geral' };
        // }
        // else{
        //     if(Object.values(this.props.alunos).length>0){
        //         aluno_select =  Object.values(this.props.alunos)[0];
        //     }
        // }
        // this.setState({ aluno_select });
        // this.props.socket.on("send_msg_fiscal", function (data) {
        //     if (data.admin_id != this.admin_id) {
        //         let alunos = { ...this.props.alunos };
        //         alunos[data.aluno_id].mensagens.push({
        //             message: data.msg,
        //             sala_id: data.sala_id,
        //             sender: { id: data.aluno_id, name: data.nome_chat },
        //             sender_id: data.aluno_id,
        //             uuid: data.id_msg,
        //             warning: false
        //         });
        //         alunos[data.aluno_id].order = moment().unix();
        //         // this.props.mudarState({ alunos });
        //         if (this.state.aluno_select.id == data.aluno_id) {


        //             alunos[data.aluno_id].counter = 0;
        //             if (this.messages_view.current.scrollTop + parseInt(this.messages_view.current.style.height.replace('px', '')) == this.messages_view.current.scrollHeight) {
        //                 setTimeout(() => {
        //                     this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        //                 }, 100);
        //             }
        //             this.props.mudarState({ alunos });
        //             this.props.socket.emit('msg_receive_aluno', { sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id });


        //         }

        //         else {
        //             alunos[data.aluno_id].counter += 1;
        //             this.props.mudarState({ alunos });
        //         }

        //     }
        // }.bind(this));

        // this.props.socket.on("send_warning_behavior_fiscal", function (data) {
        //     if (data.admin_id != this.admin_id) {

        //         let alunos = { ...this.props.alunos };
        //         alunos[data.aluno_id].mensagens.push({
        //             message: data.msg,
        //             sala_id: data.sala_id,
        //             sender: { id: data.aluno_id, name: data.nome_chat },
        //             sender_id: data.aluno_id,
        //             uuid: data.id_msg,
        //             warning: true
        //         });
        //         alunos[data.aluno_id].order = moment().unix();

        //         // $('#msg_count
        //         // $('#msg_count' + data.aluno_id).addClass('msg_count_warning');
        //         if (this.state.aluno_select.id == data.aluno_id) {
        //             alunos[data.aluno_id].counter = 0;

        //             if (this.messages_view.current.scrollTop + parseInt(this.messages_view.current.style.height.replace('px', '')) == this.messages_view.current.scrollHeight) {

        //                 setTimeout(() => {
        //                     this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        //                 }, 200);
        //             }
        //             this.props.mudarState({ alunos });
        //             this.props.socket.emit('msg_receive_aluno', { sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id, warning: true });


        //         }
        //         else {
        //             alunos[data.aluno_id].counter += 1;
        //             this.props.mudarState({ alunos });
        //         }
        //     }
        // }.bind(this));


        // this.props.socket.on("msg_receive" + this.admin_id, function (data) {
        //     // $('#' + data.id_msg).removeClass();
        //     // $('#' + data.id_msg).addClass('fas fa-check-double');
        //     let alunos = { ...this.props.alunos };
        //     for (let i = 0; i < alunos[data.aluno_id].mensagens.length; i++) {
        //         if (alunos[data.aluno_id].mensagens[i].uuid == data.id_msg) {
        //             alunos[data.aluno_id].mensagens[i].visto = true;
        //             break;
        //         }
        //     }
        //     this.props.mudarState({ alunos });
        // }.bind(this));

        // this.props.socket.on("msg_save" + this.admin_id, function (data) {
        //     // $('#' + data.id_msg).removeClass();
        //     let alunos = { ...this.props.alunos };
        //     for (let i = 0; i < alunos[data.aluno_id].mensagens.length; i++) {
        //         if (alunos[data.aluno_id].mensagens[i].uuid == data.id_msg) {
        //             alunos[data.aluno_id].mensagens[i].visto = false;
        //             break;
        //         }
        //     }
        //     this.props.mudarState({ alunos });
        //     // $('#' + data.id_msg).addClass('fas fa-check');
        // }.bind(this));


        // this.props.socket.on("finish_event", function (data) {
        //     // $('#' + data.id_msg).removeClass();
        //     console.log(data);
        //     // let alunos = { ...this.props.alunos };
        //     // for (let i = 0; i < alunos[data.aluno_id].mensagens.length; i++) {
        //     //     if (alunos[data.aluno_id].mensagens[i].uuid == data.id_msg) {
        //     //         alunos[data.aluno_id].mensagens[i].visto = false;
        //     //         break;
        //     //     }
        //     // }
        //     // this.props.mudarState({ alunos });
        //     // $('#' + data.id_msg).addClass('fas fa-check');
        // }.bind(this));
    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
    }

    receive_all(uuid, sala_id, aluno_id) {
        fetch(`${URL}api/avaliador/receive_all`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uuid,
                sala_id, aluno_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }




    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    

    send_comunicado() {
        let msg = this.state.texto;

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            if (this.props.id_candidato == 'comunicado' ) {
                let id_msg = this.uuidv4();
                this.props.socket.emit("comunicado_geral", { avaliador:true,id_msg, nome_chat: 'Comunicado Geral', msg: this.state.texto, id_adm: this.props.user.id, faculdade_id: this.props.faculdade.id });
                let comunicados = [...this.props.comunicados];
                comunicados.push({
                    message: msg,
                    id_msg,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss')

                });
                this.props.mudarState({ comunicados });
                this.setState({ texto: '' }, () => {
                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                });
            }
            

        }
    }



    render() {
        let aluno = this.props.alunos.filter((item)=>item.id==this.props.id_candidato)[0];

        return (
            <div
                style={{
                    background: 'white', height: '100vh', width: '100%',
                    bottom: this.state.chat_open == -1 ? '-400px' : 0,
                    right: '15px',
                    borderRadius: '5px',
                    wordBreak: 'break-word',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                }} id="chat_father">


                <div className="row">
                   
                    <div className="col-12">
                        <div style={{
                            boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px"
                        }}>

                        <a id="close_chat"

                            style={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px', backgroundColor: '#f2f2f2', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>

                            <p style={{ margin: 0,fontSize:14,fontWeight:'bold', color: 'black', textAlign: 'center' }}>{this.props.id_candidato=='comunicado'?'Comunicado Geral':(aluno!=undefined?aluno.name:'')}</p>
                            <button type="button" className="close" data-dismiss="alert" aria-label="Close" style={{
                                position: 'absolute',
                                left: '10px',
                                // color: 'white',
                                fontSize: '2rem'
                            }}
                                onClick={() => {
                                    this.props.mudarChatOpen(-1);
                                }}
                            >
                                <span aria-hidden="true"><i className="mdi mdi-close"></i></span>
                            </button>

                        </a>
                        {/* {this.props.id_candidato != 'comunicado' && <div style={{ display: 'flex', background: `#f2f2f2`, height: 40 }}>
                            <div onClick={() => {
                                this.setState({ tab: 0 },()=>{
                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;

                                    this.props.sendReceive();
                                });
                            }} style={{ cursor: 'pointer', borderBottom: this.state.tab == 0 ? '2px solid #17a2b8' : 0, width: '50%', padding: 5 }}>
                                <p style={{ marginBottom: 0, fontSize: '14px', textAlign: 'center', color: this.state.tab == 0 ? '#17a2b8' : 'black', }}><i
                                    className="mdi mdi-chat"
                                    style={{ fontSize: '14px', textAlign: 'center', color: this.state.tab == 0 ? '#17a2b8' : 'black', fontStyle: 'normal' }}>
                                </i>MENSAGENS</p>
                            </div>
                            <div onClick={() => {

                                this.setState({ tab: 1 },()=>{
                                this.requests_view.current.scrollTop = this.requests_view.current.scrollHeight;

                                });
                            }} style={{ cursor: 'pointer', borderBottom: this.state.tab == 1 ? '2px solid #17a2b8' : 0, width: '50%', padding: 5 }}>
                                <p style={{ marginBottom: 0, fontSize: '14px', textAlign: 'center', color: this.state.tab == 1 ? '#17a2b8' : 'black', }}><i
                                    className="mdi mdi-pause-circle-outline"
                                    style={{ fontSize: '14px', textAlign: 'center', color: this.state.tab == 1 ? '#17a2b8' : 'black', fontStyle: 'normal' }}>
                                </i> ANOTAÇÕES</p>
                            </div>

                        </div>} */}
                        </div>

                        <div hidden={this.state.tab != 0}>

                            <div ref={this.messages_view} style={{ height: window.innerHeight - 40 - 70 - (this.props.id_candidato != 'comunicado'?0:0), padding: 0, margin: 0 }} className="overflow-auto" id="body_msg">
                                {this.props.id_candidato == 'comunicado' && this.props.comunicados.filter((item, id) => {
                                    if (item.sala_id == null) {
                                        return true;
                                    }
                                    else {
                                        return false;
                                    }
                                }).map((item, id) => (
                                    <div key={id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0.5rem' }}>
                                        <div style={{ padding: '5px', backgroundColor:  '#f2f2f2', borderRadius: '5px',  width:'100%' }} >
                                            <p style={{ marginBottom: 0,  fontWeight: 'bold', fontSize: '0.9rem' }} className={'text-info'}>{'Comunicado Geral'}</p>
                                            <p style={{ marginBottom: 0, color: 'black',fontSize:14 }}>{item.message}</p>
                                            <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                                <span style={{ fontSize: 10, marginRight: 2, color: 'black' }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</span>
                                                {item.send == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0, color: 'black' }}></i>}
                                                {item.send == true && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0, color: 'black' }}></i>}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {aluno!=undefined && this.props.id_candidato  != 'comunicado' && aluno.mensagens.map((item, id) => {
                                    if (item.warning == true) {
                                        // return (
                                        //     <div key={id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                        //         <div style={{ padding: '5px', backgroundColor: 'lightyellow', marginRight: '0.5rem', borderRadius: '5px', textAlign: 'center', display: 'flex', alignItems: 'flex-end' }} >
                                        //             <p style={{ marginBottom: 0, color: 'black', marginLeft: '1rem', marginRight: '1rem', textAlign: 'center', textAlign: 'center' }}>{item.message}</p>
                                        //         </div>
                                        //     </div>
                                        // )
                                        return;
                                    }
                                    else if (item.receive_id == aluno.id && item.sender_id == this.props.user.id) {
                                        return (
                                            <div key={id} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <div style={{ padding: '5px', backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '3rem', borderRadius: '5px', textAlign: 'justify' }} >
                                                    <p style={{ marginBottom: 0, color: 'black', marginRight: '1rem',fontSize:14 }}>{item.message}</p>
                                                    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                                                        <span style={{ fontSize: 10, marginRight: 2 }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</span>
                                                        {item.visto == undefined && <i className="fas fa-clock" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                        {item.visto == false && <i className="fas fa-check" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                        {item.visto == true && <i className="fas fa-check-double" style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    else if (item.sender_id == aluno.id) {
                                        return (
                                            <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '3rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}>
                                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.sender.name}</p>
                                                    <p style={{ marginBottom: 0, color: 'black',fontSize:14 }}>{item.message}</p>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    else {
                                        return (
                                            <div key={id} style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '3rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}>
                                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '0.9rem' }}>{item.sender.name}</p>
                                                    <p style={{ marginBottom: 0, color: 'black',fontSize:14 }}>{item.message}</p>
                                                    <div style={{ display: 'flex' }}>
                                                        <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3, marginLeft: '0.5rem' }}>{moment(item.created_at).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                            </div>

                            <div
                                style={{ height: '70px', backgroundColor: '#f2f2f2', borderTop: '1px solid lightgrey', padding: '5px', display: 'flex', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                                <input autoComplete="off" onKeyPress={(e) => {
                                    let keyCode = e.keyCode || e.which;
                                    if (keyCode == 13) {
                                        if (this.props.id_candidato != 'comunicado') {
                                            if(this.props.send_message(this.state.texto)){
                                                this.setState({texto:''});
                                                setTimeout(() => {
                                                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                                }, 100);
                                            }
                                        }
                                        else {
                                            this.send_comunicado();
                                        }
                                    }
                                }} onChange={(e) => this.setState({ texto: e.target.value })} value={this.state.texto} name="msg" placeholder="Mensagem..." className="form-control msg_input" style={{ resize: 'none', height: '100%' }} />
                                <div className="send_msg" onClick={() => {
                                    if (this.props.id_candidato != 'comunicado') {
                                        if(this.props.send_message(this.state.texto)){
                                            this.setState({texto:''});
                                            setTimeout(() => {
                                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                            }, 100);
                                        }
                                    }
                                    else {
                                        this.send_comunicado();
                                    }
                                }}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px',cursor:'pointer' }}>
                                    <i className="mdi mdi-send" style={{ fontSize: '1.4rem' }}></i>

                                </div>
                            </div>
                        </div>

                        <div hidden={this.state.tab != 1}>
                            <div ref={this.requests_view} style={{ height: window.innerHeight - 40  - 40, padding: 0, margin: 0 }} className="overflow-auto" id="body_requests">
                              
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        sala: state.AppReducer.sala,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
        faculdade: state.AppReducer.faculdade,
    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarMensagens, mudarChatOpen })(ChatIndividual));


