import { useSelector, useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { logout, mudarSocket } from '../actions/AppActions';
import { URL_SOCKET } from '../variables';
import io from 'socket.io-client';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
var salaGlobal=null;
var socketGlobal=null;
var salasGlobal = [];
const Interview = (props) => {
    const dispatch = useDispatch();
    const [audio, setAudio] = useState(false);
    const [video, setVideo] = useState(false);
    const [connected, setConnected] = useState(false);

    const [sala, setSala] = useState({});
    const [processo, setProcesso] = useState({});

    const [line, setLine] = useState([]);

    const [salas, setSalas] = useState([]);


    const [height, setHeight] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);


    const stream_ref = useRef();
    const videoRefCandidate = useRef();
    const videoRefFiscal = useRef();
    const timeoutInterval = useRef();

    const adminId = useRef();




    const params = useParams();
    const location = useLocation();

    salasGlobal=[]
    salas.map((item)=>{
        salasGlobal.push(item.id);
    })


    const token = useSelector(state => state.AppReducer.token);
    // const socket = useSelector(state => state.AppReducer.socket);

    const faculdade = useSelector(state => state.AppReducer.faculdade);
    let sala_select = sala;
    salaGlobal=sala;

   

    const connectSocket = useCallback((client) => {
        setConnected(true)
        console.log('connected')
        if (salaGlobal.id != undefined) {
            socketGlobal.emit("join", { salas_ids:salasGlobal,sala_id: salaGlobal.id, name: 'AdminPresentation', faculdade_id: faculdade.id, admin_id: adminId.current });
        }
    }, [salaGlobal, faculdade, adminId.current, socketGlobal])

    const disconnectSocket = useCallback((client) => {
        setConnected(false)
        console.log('disconnected')
    }, [])

    const getLinesCountSocket = useCallback((data) => {
        console.log(data);
        console.log(salaGlobal)
        if (data.sala_id == salaGlobal.id) {
            setLine(data.number_line);
        }

    }, [salaGlobal])

    const intervalLine = useCallback(() => {
        console.log(salaGlobal)
        console.log(adminId.current)

        if (salaGlobal.id != undefined) {
            socketGlobal.emit('get_lines_count', { sala_id: salaGlobal.id, admin_id: adminId.current })
        }
    }, [sala, socketGlobal]);

    useEffect(() => {
        adminId.current = uuidv4();
        socketGlobal = io.connect(URL_SOCKET, { "transports": ['websocket'], query: { token: token } });
        dispatch(mudarSocket(socketGlobal))

        // socket.emit("join", {name:'Admin',sala_id:sala_id,faculdade_id:faculdade_id});
        socketGlobal.on("connect", connectSocket);

        socketGlobal.on('disconnect', disconnectSocket);

        socketGlobal.on("get_lines_count", getLinesCountSocket);
        clearInterval(timeoutInterval.current);
        timeoutInterval.current = setInterval(intervalLine, 10000);

        const search = location.search;
        const sala_id = new URLSearchParams(search).get('sala_id');
        // getSalas(sala_id, socketGlobal);


        return () => {
            clearInterval(timeoutInterval.current);
        }

    }, []);

    useEffect(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight)

    }, [window.innerHeight, window.innerWidth]);


    console.log(line)

    return (
        <>
            <div style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, background: 'rgb(32,33,36)', zIndex: 1000,
            display:'flex',justifyContent:'center',alignItems:'center'
        }}>
                <video autoPlay ref={videoRefCandidate} style={{ width: '100vw', height: '100%' }}> </video>
                {line.length>0 && 
                <div style={{position:'absolute'}}>
                <button className='btn btn-success'>Buscar próximo da fila <i className='mdi mdi-human'></i></button>
                </div>
                }

                <div style={{
                    position: 'fixed', bottom: '5rem', right: 10, borderRadius: 5, overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: 'rgb(60,64,67)',
                    width: width / 4,
                    height: height / 4
                }}>
                    {<video hidden={!video} autoPlay muted ref={videoRefFiscal} style={{
                        width: width / 4,
                        height: height / 4
                    }}> </video>}


                    <div hidden={video} style={{ borderRadius: 100, width: height / 8, height: height / 8, background: 'dodgerblue', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p style={{ marginBottom: 0, fontSize: 50, color: 'white' }}>F</p>
                    </div>

                </div>


                <div style={{ background: 'rgb(32,33,36)', height: '5rem', width: '100vw', position: 'absolute', bottom: 0, right: 0, left: 0, zIndex: 1001, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className={audio ? 'button_call_on' : 'button_call_off'} onClick={(e) => {
                        if (audio == true) {
                            let track = stream_ref.current.getAudioTracks()[0];
                            track.stop();
                            stream_ref.current.removeTrack(track);
                            setAudio(false);
                        }
                        else {
                            navigator.mediaDevices.getUserMedia({
                                audio: true
                            }).then((stream) => {
                                let track = stream.getAudioTracks()[0];
                                stream_ref.current.addTrack(track);
                                // videoRefFiscal.current.srcObject = stream;
                                setAudio(true);

                            }).catch((e) => {
                                console.log(e)
                            })
                        }
                    }}>
                        <i className={audio ? 'mdi mdi-microphone' : 'mdi mdi-microphone-off'}></i>
                    </div>
                    <div className={video ? 'button_call_on' : 'button_call_off'} onClick={(e) => {
                        if (video == true) {
                            let track = stream_ref.current.getVideoTracks()[0];
                            track.stop();
                            stream_ref.current.removeTrack(track);
                            setVideo(false);
                        }
                        else {
                            navigator.mediaDevices.getUserMedia({
                                video: {
                                    width: 640,
                                    height: 480,
                                    facingMode: "user",
                                    frameRate: 24,
                                }
                            }).then((stream) => {
                                let track = stream.getVideoTracks()[0];
                                stream_ref.current.addTrack(track);
                                // videoRefFiscal.current.srcObject = stream;
                                setVideo(true);

                            }).catch((e) => {
                                console.log(e)
                            })
                        }
                    }}>
                        <i className={video ? 'mdi mdi-camera' : 'mdi mdi-camera-off'}></i>
                    </div>
                </div>
            </div>

        </>

    );

}

export default Interview;