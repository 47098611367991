import React from 'react';

const INITIAL_STATE = {
    token: null,
    user: {},
    loading_home: true,
    sala: { id: -1 },
    processo: null,
    socket: null,
    mensagens: {},
    faculdade: {},
    sala_screen:false,
    chat_open:-1,
    show_call:false,
    connect:false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'connect':
            return {
                ...state,
                connect: action.payload,
            };
        case 'chat_open_individual':
            return {
                ...state,
                chat_open: action.payload,
            };
        case 'show_call':
            return {
                ...state,
                show_call: action.payload,
            };
        case 'sala_screen_change':
            return {
                ...state,
                sala_screen: action.payload,
            };
        case 'mensagens':
            return {
                ...state,
                mensagens: action.payload,
            };
        case 'faculdade':
            return {
                ...state,
                faculdade: action.payload,
            };
        case 'sala':
            return {
                ...state,
                sala: action.payload,
            };
        case 'processo':
            return {
                ...state,
                processo: action.payload,
            };

        case 'socket':
            return {
                ...state,
                socket: action.payload,
            };
        case 'loading_home':
            return {
                ...state,
                loading_home: action.payload,
            };
        case 'logout':
            return {
                ...state,
                token: '',
                user: {},
            };
        case 'user':
            return {
                ...state,
                user: action.payload,
            };
        case 'login':
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,

            };
        default:
            return { ...state };
    }
};
