import { URL } from "../variables"


const getStudents = async (processo_id,token,page=1,search='')=>{
    return fetch(`${URL}api/avaliador/get_candidatos_processo/${processo_id}?page=${page}&search=${search}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',

            'Authorization': `Bearer ${token}`,
            // 'Content-Type': 'application/json',
        }
    });
}



export {getStudents};