
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { logout, mudarMensagens } from '../actions/AppActions';
import { connect } from 'react-redux';

import moment from 'moment-timezone';
import { URL } from '../variables';

class Chat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect: false,
            path: '/',
            texto: '',
            chat_open: false,
            alunos: {},
            aluno_select: { mensagens: [] },
            comunicados: [],
            name_filter: ''
        };
        this.messages_view = React.createRef();
        this.clearTimeoutMovement = {}
    }

    listen_channels(){
        this.props.socket.on("send_msg_avaliador", function (data) {
            console.log(data)
            if (data.admin_id != this.admin_id) {
                let alunos = [ ...this.props.alunos ];
                let aluno = alunos.filter((item)=>item.id==data.aluno_id)[0];
                if (aluno != undefined) {

                    aluno.mensagens.push({
                        message: data.msg,
                        sala_id: data.sala_id,
                        sender: { id: data.aluno_id, name: data.nome_chat },
                        sender_id: data.aluno_id,
                        uuid: data.id_msg,
                        warning: false,
                        created_at: moment().format('YYYY-MM-DD HH:mm:ss')

                    });
                    aluno.order = moment().unix();
                    // this.setState({ alunos });
                   if (this.props.id_candidato == data.aluno_id && (this.props.chat_open == 0 || this.props.show_chat_meet==true)) {
                        aluno.counter = 0;
                        alunos = alunos.map((item)=>{
                            if(item.id==aluno.id){
                                return aluno;
                            }
                            return item;
                        })
                        if (this.messages_view.current.scrollTop + parseInt(this.messages_view.current.style.height.replace('px', '')) == this.messages_view.current.scrollHeight) {
                            setTimeout(() => {
                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                            }, 100);
                        }
                        this.props.mudarState({ alunos });
                        this.props.socket.emit('msg_receive_aluno', { avaliador:true,sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id });
                    }
                    else if(data.from_admin!=true) {
                        aluno.counter += 1;
                        alunos = alunos.map((item)=>{
                            if(item.id==aluno.id){
                                return aluno;
                            }
                            return item;
                        })
                        this.props.mudarState({ alunos });
                    }
                    else{
                        alunos = alunos.map((item)=>{
                            if(item.id==aluno.id){
                                return aluno;
                            }
                            return item;
                        })
                        this.props.mudarState({ alunos });
                    }
                }

            }
        }.bind(this));

        

        this.props.socket.on("send_warning_behavior_avaliador", function (data) {
            if (data.admin_id != this.admin_id) {

                let alunos = [ ...this.props.alunos ];
                let aluno = alunos.filter((item)=>item.id==data.aluno_id)[0];

                if (aluno != undefined) {

                    aluno.mensagens.push({
                        message: data.msg,
                        sala_id: data.sala_id,
                        sender: { id: data.aluno_id, name: data.nome_chat },
                        sender_id: data.aluno_id,
                        uuid: data.id_msg,
                        warning: true,
                        created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                    aluno.order = moment().unix();
                    let video_blink = { ...this.props.video_blink }
                    if (this.props.online.includes(data.aluno_id.toString())) {
                        video_blink[data.aluno_id] = data.type == 'in' ? false : true;
                    }
                    else {
                        video_blink[data.aluno_id] = false;
                    }

                    // $('#msg_count
                    // $('#msg_count' + data.aluno_id).addClass('msg_count_warning');
                    // if (this.state.id_candidato == data.aluno_id) {
                    //     aluno.counter = 0;
                    //     alunos = alunos.map((item)=>{
                    //         if(item.id==aluno.id){
                    //             return aluno;
                    //         }
                    //         return item;
                    //     })
                    //     if (this.messages_view.current.scrollTop + parseInt(this.messages_view.current.style.height.replace('px', '')) == this.messages_view.current.scrollHeight) {

                    //         setTimeout(() => {
                    //             this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                    //         }, 200);
                    //     }
                    //     this.props.mudarState({ alunos, video_blink });
                    //     this.props.socket.emit('msg_receive_aluno', { avaliador:true,sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id, warning: true });


                    // }
                    if (this.props.id_candidato == data.aluno_id && (this.props.chat_open == 0 || this.props.show_chat_meet==true)) {
                        aluno.counter = 0;
                        alunos = alunos.map((item)=>{
                            if(item.id==aluno.id){
                                return aluno;
                            }
                            return item;
                        })
                        this.props.mudarState({ alunos, video_blink });
                        this.props.socket.emit('msg_receive_aluno', {avaliador:true, sala_id: data.sala_id, aluno_id: data.aluno_id, uuid: data.id_msg, faculdade_id: this.props.faculdade.id, warning: true });
                    }
                    else {
                        // aluno.counter += 1;
                        alunos = alunos.map((item)=>{
                            if(item.id==aluno.id){
                                return aluno;
                            }
                            return item;
                        })
                        this.props.mudarState({ alunos, video_blink });
                    }

                }
            }
        }.bind(this));


        this.props.socket.on("msg_receive", function (data) {
            // $('#' + data.id_msg).removeClass();
            // $('#' + data.id_msg).addClass('fas fa-check-double');
            let alunos = [ ...this.props.alunos ];
            let aluno = alunos.filter((item)=>item.id==data.aluno_id)[0];
            
            if (aluno != undefined) {

                for (let i = 0; i < aluno.mensagens.length; i++) {
                    if(data.all==true && aluno.mensagens[i].sender.id==this.props.user.id){
                        aluno.mensagens[i].visto = true;
                    }
                    else if (aluno.mensagens[i].uuid == data.id_msg) {
                        aluno.mensagens[i].visto = true;
                        break;
                    }
                }
                alunos = alunos.map((item)=>{
                    if(item.id==aluno.id){
                        return aluno;
                    }
                    return item;
                })
                this.props.mudarState({ alunos });
            }
        }.bind(this));

        this.props.socket.on("msg_save" + this.admin_id, function (data) {
            // $('#' + data.id_msg).removeClass();
            console.log('save',data)
            let alunos = [ ...this.props.alunos ];
            let aluno = alunos.filter((item)=>item.id==data.aluno_id)[0];

            if (aluno != undefined) {

                for (let i = 0; i < aluno.mensagens.length; i++) {
                    if (aluno.mensagens[i].uuid == data.id_msg) {
                        aluno.mensagens[i].visto = false;
                        break;
                    }
                }
                alunos = alunos.map((item)=>{
                    if(item.id==aluno.id){
                        return aluno;
                    }
                    return item;
                })
                this.props.mudarState({ alunos });
            }
            // $('#' + data.id_msg).addClass('fas fa-check');
        }.bind(this));


    


        this.props.socket.on("msg_receive_comunicado", function (data) {
            // $('#' + data.id_msg).removeClass();
            // $('#' + data.id_msg).addClass('fas fa-check-double');
            let comunicados = this.props.comunicados;
            console.log(comunicados)
            console.log(data)
            let breaked = false;
            for (let i = 0; i < comunicados.length; i++) {
                if (comunicados[i].id_msg == data.id_msg) {
                    comunicados[i].send = true;
                    breaked = true;
                    break;
                }
            }
            if (breaked == false) {
                comunicados.push({
                    message: data.msg,
                    sala_id: data.sala_id,
                    created_at: moment().format('YYYY-MM-DD HH:mm:ss')
                });
            }
            this.props.mudarState({ comunicados });
        }.bind(this));
    }

    componentDidMount() {
        console.log('mount')
        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
        this.admin_id = this.props.admin_id;

        this.listen_channels();
        // let aluno_select={};
        // if(this.props.user.role=='fiscal_coordenador'){
        //     aluno_select =  { id: 'comunicado', name: 'Comunicado Geral' };
        // }
        // else{
        //     if(Object.values(this.props.alunos).length>0){
        //         aluno_select =  Object.values(this.props.alunos)[0];
        //     }
        // }
        // // this.props.mudarState({ alunos: this.props.alunos,  comunicados: this.props.comunicados });
        // this.setState({aluno_select});
        
    }
   

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
    }

    receive_all(uuid, sala_id, aluno_id) {
        fetch(`${URL}api/avaliador/receive_all`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                uuid,
                sala_id, aluno_id
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                else {

                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }


    componentDidUpdate(props) {
        if (props.show_modal_chat_geral == true && this.props.show_modal_chat_geral == false) {
            this.setState({ aluno_select: { mensagens: [] }, });
        }
        
        
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    send_message() {
        let uid_msg = this.uuidv4();
        let msg = this.state.texto;

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            this.props.socket.emit("send_msg", { processo_id:this.props.processo.id, avaliador:true,msg: msg, sala_id: this.state.aluno_select.sala_id, aluno_id: this.state.aluno_select.id, nome_chat: this.props.user.name, id_msg: uid_msg, admin_id: this.admin_id, faculdade_id: this.props.faculdade.id, id_adm: this.props.user.id });
            // $(this).val('');
            // $('#body_msg').append(template_send.replace(/%MSG%/g,msg).replace(/%ID%/g,uid_msg));
            // $('#body_msg').scrollTop($('#body_msg')[0].scrollHeight);
            let alunos = [ ...this.props.alunos ];
            // let aluno_select={...this.state.aluno_select};
            // aluno_select.mensagens.push({
            //     sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
            //     receive_id: this.state.aluno_select.id, sala_id:this.state.aluno_select.sala_id, uuid: uid_msg, visto: false, message: msg
            // });
            alunos[this.state.aluno_select.id].mensagens.push({
                sender: { id: this.props.user.id, name: this.props.user.name, }, sender_id: this.props.user.id,
                receive_id: this.state.aluno_select.id, sala_id: this.state.aluno_select.sala_id, uuid: uid_msg, visto: undefined, message: msg,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')

            });
            this.setState({ texto: '' });
            this.props.mudarState({ alunos: alunos });
            setTimeout(() => {
                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
            }, 100);
        }
    }

    send_comunicado() {
        let msg = this.state.texto;

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            let id_msg = this.uuidv4();

            this.props.socket.emit("comunicado_geral", { avaliador:true,id_msg, nome_chat: 'Comunicado Geral', msg: this.state.texto, id_adm: this.props.user.id, faculdade_id: this.props.faculdade.id });
            let comunicados = [...this.props.comunicados];
            comunicados.push({
                message: msg,
                id_msg,
                created_at: moment().format('YYYY-MM-DD HH:mm:ss')

            });
            this.props.mudarState({ comunicados: comunicados });
            this.setState({ texto: '' });

        }
    }




    render() {
        let alunos = Object.values(this.props.alunos);
        alunos = alunos.sort(function (a, b) { return b.order - a.order })
        let online = this.props.online;
        return (
         <div ref={this.messages_view} hidden></div>

        );
    }
}
const mapsStateToProps = (state) => (
    {
        tempo_atual: state.AppReducer.tempo_atual,
        processo: state.AppReducer.processo,
        sala: state.AppReducer.sala,
        token: state.AppReducer.token,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
        faculdade: state.AppReducer.faculdade,
        chat_open: state.AppReducer.chat_open,
    }
);

export default withRouter(connect(mapsStateToProps, { logout, mudarMensagens })(Chat));


