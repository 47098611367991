import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';

import moment from 'moment-timezone';
import { Modal, Button } from 'react-bootstrap'

class ReportAction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            processos: [],
            candidato_id: '',
            loading: false,
            acao: '',
            msgErro: ''
        }
        this.videoRef = React.createRef();

        // this.videoRef=null;
    }
    componentDidMount() {
        // this.get_processos();

    }

    componentWillUnmount() {
        // clearTimeout(this.logs_timeout);
    }

    async get_processos() {
        this.setState({ loading_screen: true });
        fetch(`${URL}api/avaliador/get_processos/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);
                    setTimeout(() => {
                        this.get_processos();
                    }, 5000);
                }
                else {
                    this.setState({ loading_screen: false, processos: resp.processos });

                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_processos();
                }, 5000);

            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_processos();
                }, 5000);
            });
    }


    salvar() {
        this.setState({ loading: true, msgErro: '' });
        fetch(`${URL}api/avaliador/create_annotation`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                acao: this.state.acao,
                fiscal_id: this.props.user.id,
                candidato: this.props.candidato_select.id,
                processo_id: this.props.processo.id,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro });
                }
                else {

                    this.setState({ loading: false,acao:'' });
                    this.props.setState({show_modal_action: false});

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }










    render() {
        // let sala_id = this.props.sala != '' ? this.props.sala.id : '';
        let candidato_select=this.props.candidato_select;
        // let candidatos = this.props.candidatos.filter((item, id) => {
        //     if (item.sala_id != sala_id) {
        //         return false;
        //     }
        //     return true;
        // });
        return (
            <>
                {/* <Button variant="primary" onClick={() => this.setState({ show_modal_action: true,candidato_id:'',acao:'' })}>
                    Reportar Ação Suspeita
                </Button> */}


                <Modal
                    show={this.props.show_modal_action}
                    onHide={() => this.props.setState({ show_modal_action: false })}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <span style={{color:'black'}}>Reportar Ação Suspeita</span>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.msgErro != '' && <div>
                            <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>

                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                            </div>
                            <br />

                        </div>}
                        {candidato_select.id!=undefined && <div className="form-group row mb-0">
                            <div className="col-sm-12">
                                <p style={{color:'black',marginBottom:5,fontSize:15}}><b style={{marginRight: "2px"}}>Nome: </b>{candidato_select.name}</p>
                                <p style={{color:'black',marginBottom:5,fontSize:15}}><b style={{marginRight: "2px"}}>Sala: </b> Sala {this.props.sala.numero}</p>
                            </div>
                        </div>}
                        {/* <div className="form-group row">
                            <label className="col-sm-12 col-form-label">Selecione um candidato</label>
                            <div className="col-sm-12">
                                <select value={this.state.candidato_id} className="custom-select" id="cameras" onChange={(e) => {
                                    this.setState({ candidato_id: e.target.value })
                                }}>
                                    <option value={''}>Selecione um candidato</option>

                                    {candidatos.map((item, id) => (
                                        <option key={id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div> */}
                        <div className="form-group row">
        
                            <label className="col-sm-12 col-form-label" style={{color:'black',fontSize:15}}>Ação <span style={{color: "red", fontWeight: "700"}}>*</span></label>
                            <div className="col-sm-12">
                                <textarea className="form-control" style={{fontSize:15}} value={this.state.acao} placeholder="Ação a ser relatada" type="text" onChange={(e) => this.setState({ acao: e.target.value })} />

                            </div>
                        </div>
                    </Modal.Body>
                    {this.state.loading == false && <Modal.Footer>
                        <Button variant="secondary" className="btn-lg" onClick={() => this.props.setState({ show_modal_action: false })}>Fechar</Button>
                        <Button className="btn-lg" onClick={() => this.salvar()}>Enviar</Button>

                    </Modal.Footer>}
                    {this.state.loading == true && <Modal.Footer><div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div></div></Modal.Footer>}
                </Modal>

            </>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout })(ReportAction);
