import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import reducers from './reducers';


import './assets/css/icons.css';
import './assets/css/style.css';
import './assets/css/bootstrap.min.css';
import { DEBUG } from './variables';
if(!DEBUG){
  console.log = () => {};
}
const root = ReactDOM.createRoot(document.getElementById('root'));

ReactDOM.render(
  <Provider store={createStore(reducers, {}, applyMiddleware(ReduxThunk))}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
