// export const URL = "http://localhost:8000/";
// export const URL_SOCKET = "http://localhost:5001";

export const URL = "https://api.ontest.com.br/";
export const URL_SOCKET = "https://node.ontest.com.br";
export const DEBUG=true;
export const TURN_1 = "turn1.ontest.com.br";
export const TURN_2 = "turn2.ontest.com.br";
export const TURN_3 = "turn3.ontest.com.br";
export const TURN_4 = "turn4.ontest.com.br";

// export const URL = "https://onteste.enterscience.com.br/";
// export const URL_SOCKET = "https://node.enterscience.com.br";

// export const TURN_1 = "turn1.enterscience.com.br";
// export const TURN_2 = "turn2.enterscience.com.br";
// export const TURN_3 = "turn3.enterscience.com.br";
// export const TURN_4 = "turn4.enterscience.com.br";